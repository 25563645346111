import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import LoginForm from './loginLayout';
import ResetForm from './resetLayout';
import Reset2Form from './reset2Layout';
import RegisterForm from './registerLayout';
import VerifyForm from './verifyLayout';
import NotFound from './notFound.jsx';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { messages } from './locales/sv/messages.js';
import CssBaseline from '@material-ui/core/CssBaseline';
import { telkeyLight } from './themes';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';

i18n.load('sv', messages);
i18n.activate('sv');

const basename = process.env.REACT_APP_BASENAME ?? "/"

ReactDOM.render(
  <I18nProvider i18n={i18n}>
    <ThemeProvider theme={telkeyLight}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <BrowserRouter basename={basename}>
        <Routes>
          <Route path="/" element={<LoginForm />} />
          <Route path="/register" element={<RegisterForm />} />
          <Route path="/verify/:token" element={<VerifyForm />} />
          <Route path="/reset" element={<ResetForm />} />
          <Route path="/reset2/:token" element={<Reset2Form />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </I18nProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
