import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import { useParams } from 'react-router-dom'
// import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { Form } from "formik";
import formStyles from "./formStyles";
import { Trans, t } from "@lingui/macro";
import Alert from "@material-ui/lab/Alert";
import { Link as ReactLink } from "react-router-dom";


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://telkey.com/">
        Telkey Solutions AB
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function VerifyForm(props) {
  const { token } = useParams()
  const classes = formStyles();
  const [httpResponse, setHttpResponse] = useState({});

  const getApiData = async () => {
    fetch(
      "/account/verify/" + token
    ).then((response) => response.json())
      .then((data) => {
        setHttpResponse(data);
        console.log("Success:", data);
      })
      .catch((error) => {
        setHttpResponse({ error_code: "500" });
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    getApiData();
  }, [token]);

  return (
    <Grid container component="main" className={classes.root}>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        lg={4}
        xl={3}
        component={Paper}
        elevation={6}
        square
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            <Trans>account verification</Trans>
          </Typography>

          {(httpResponse.error_code === "40000" && (
            <Alert variant="outlined" severity="error">
              <Trans>invalid link</Trans>
            </Alert>
          )) ||
            (httpResponse.error_code === "40101" && (
              <Alert variant="outlined" severity="error">
                <Trans>invalid token</Trans>
              </Alert>
            )) ||
            (httpResponse.error_code === "40007" && (
              <Alert variant="outlined" severity="error">
                <Trans>token expired</Trans>
              </Alert>
            )) ||
            (httpResponse.error_code === "500" && (
              <Alert variant="outlined" severity="error">
                <Trans>
                  Something went wrong. This problem is with the server.
                  Please contact support if the problem persists.
                </Trans>
              </Alert>
            )) ||
            (httpResponse.status === "success" && (
              <Alert variant="outlined" severity="success">
                <Trans>verification successful</Trans>
              </Alert>
            )) ||
            (httpResponse && (
              //Default
              <Alert variant="outlined" severity="error">
                <Trans>
                  Something went wrong. Please contact admin if the
                  problem persists.
                </Trans>
              </Alert>
            ))}
          <Grid container>
            <Grid item xs>
              <Link component={ReactLink} to="/" variant="body2">
                <Trans>Already have an account? Log in here</Trans>
              </Link>
            </Grid>
            <Grid item>
              <Link component={ReactLink} to="/register" variant="body2">
                <Trans>noaccount signup</Trans>
              </Link>
            </Grid>
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
          {/* </form> */}
        </div>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        lg={8}
        xl={9}
        className={classes.image}
      />
    </Grid>
  );
}
