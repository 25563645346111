import React, { useState } from "react";
// import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import formStyles from "./formStyles";
import { Trans, t } from "@lingui/macro";
import { Link as ReactLink } from "react-router-dom";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://telkey.com/">
                Telkey Solutions AB
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

export default function NotFound() {
    const classes = formStyles();
    const [httpResponse, setHttpResponse] = useState("");

    return (
        <Grid container component="main" className={classes.root}>
            <Grid
                item
                xs={12}
                sm={8}
                md={5}
                lg={4}
                xl={3}
                component={Paper}
                elevation={6}
                square
            >
                <div className={classes.paper}>
                    <Typography component="h1" variant="h3">
                        <Trans>404 - Page not found</Trans>
                    </Typography>
                    <Typography component="h1" variant="h5">
                        <Trans>Maybe you were looking for one of these?</Trans>
                    </Typography>
                    <Grid container>
                        <Grid item xs>
                            <Link component={ReactLink} to="reset" variant="body2">
                                <Trans>forgot password</Trans>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link component={ReactLink} to="register" variant="body2">
                                <Trans>noaccount signup</Trans>
                            </Link>
                        </Grid>
                    </Grid>
                    <Box mt={5}>
                        <Copyright />
                    </Box>
                    {/* </form> */}
                </div>
            </Grid>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                lg={8}
                xl={9}
                className={classes.image}
            />
        </Grid>
    );
}
