import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import { useParams } from 'react-router-dom'
// import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { Formik, Form, Field } from "formik";
import { Button, LinearProgress } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import formStyles from "./formStyles";
import { Trans, t } from "@lingui/macro";
import Alert from "@material-ui/lab/Alert";
import { Link as ReactLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import PasswordStrengthBar from "react-password-strength-bar";
import zxcvbnInputs from "./constants";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://telkey.com/">
        Telkey Solutions AB
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Reset2Form(props) {
  const { token } = useParams()
  const classes = formStyles();
  const [httpResponse, setHttpResponse] = useState("");
  const navigate = useNavigate()

  return (
    <Grid container component="main" className={classes.root}>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        lg={4}
        xl={3}
        component={Paper}
        elevation={6}
        square
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            <Trans>set new password</Trans>
          </Typography>
          <Formik
            initialValues={{
              password: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              fetch("/account/setpw/" + token, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(values),
              })
                .then((response) => {
                  if (response.redirected) {
                    navigate(response.url);
                    return JSON.stringify({ status: "success" });
                  }
                  return response.json();
                })
                .then((data) => {
                  setHttpResponse(data);
                  console.log("Success:", data);
                })
                .catch((error) => {
                  setHttpResponse({ error_code: "500" });
                  console.error("Error:", error);
                })
                .finally(() => setSubmitting(false));
            }}
          >
            {({ submitForm, isSubmitting, values }) => (
              <Form className={classes.form}>
                {(httpResponse.error_code === "40007" && (
                  <Alert variant="outlined" severity="error">
                    <Trans>password reset token has expired</Trans>
                  </Alert>
                )) ||
                  (httpResponse.error_code === "40101" && (
                    <Alert variant="outlined" severity="error">
                      <Trans>password reset invalid token</Trans>
                    </Alert>
                  )) ||
                  (httpResponse.error_code === "500" && (
                    <Alert variant="outlined" severity="error">
                      <Trans>
                        Something went wrong. This problem is with the server.
                        Please contact support if the problem persists.
                      </Trans>
                    </Alert>
                  )) ||
                  (httpResponse.error_code === "40005" && (
                    <Alert variant="outlined" severity="error">
                      <Trans>Password is not strong enough.</Trans>
                    </Alert>
                  )) ||
                  (httpResponse.status === "success" && (
                    <Alert variant="outlined" severity="success">
                      <Trans>success reset done</Trans>
                    </Alert>
                  )) ||
                  (httpResponse && (
                    //Default
                    <Alert variant="outlined" severity="error">
                      <Trans>
                        Something went wrong. Please contact admin if the
                        problem persists.
                      </Trans>
                    </Alert>
                  ))}
                <Field
                  component={TextField}
                  type="password"
                  label={t`Password`}
                  name="password"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  autoComplete="new-password"
                />
                <PasswordStrengthBar
                  password={values.password}
                  userInputs={zxcvbnInputs}
                  scoreWords={[
                    t`Too weak`,
                    t`Very weak`,
                    t`Acceptable`,
                    t`Good`,
                    t`Strong`,
                  ]}
                  shortScoreWord={t`Too weak`}
                />
                <br />
                {isSubmitting && <LinearProgress />}
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  type="submit"
                  fullWidth
                  className={classes.submit}
                >
                  <Trans>save password</Trans>
                </Button>
              </Form>
            )}
          </Formik>
          <Grid container>
            <Grid item xs>
              <Link component={ReactLink} to="/" variant="body2">
                <Trans>Already have an account? Log in here</Trans>
              </Link>
            </Grid>
            <Grid item>
              <Link component={ReactLink} to="/register" variant="body2">
                <Trans>noaccount signup</Trans>
              </Link>
            </Grid>
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
          {/* </form> */}
        </div>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        lg={8}
        xl={9}
        className={classes.image}
      />
    </Grid>
  );
}
