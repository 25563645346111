import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
// import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { Formik, Form, Field } from "formik";
import { Button, LinearProgress } from "@material-ui/core";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import * as Yup from "yup";
import formStyles from "./formStyles";
import { Trans, t } from "@lingui/macro";
import Alert from "@material-ui/lab/Alert";
import { Link as ReactLink } from "react-router-dom";
import { SendUserBack } from "./helpers/authentication.js";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://telkey.com/">
        Telkey Solutions AB
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function LoginForm() {
  const classes = formStyles();
  const [httpResponse, setHttpResponse] = useState("");

  return (
    <Grid container component="main" className={classes.root}>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        lg={4}
        xl={3}
        component={Paper}
        elevation={6}
        square
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            <Trans>Login</Trans>
          </Typography>
          <Formik
            initialValues={{
              email: "",
              password: "",
              remember: false,
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Invalid email address")
                .required("Required"),
              password: Yup.string().required("Required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              fetch("/account/login", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(values),
              })
                .then((response) => response.json())
                .then((data) => {
                  setHttpResponse(data);
                  //console.log("Success:", data);
                  SendUserBack();
                })
                .catch((error) => {
                  setHttpResponse({ error_code: "500" });
                  console.error("Error:", error);
                })
                .finally(() => setSubmitting(false));
            }}
          >
            {({ submitForm, isSubmitting }) => (
              <Form className={classes.form}>
                {(httpResponse.error_code === "40100" && (
                  <Alert variant="outlined" severity="error">
                    <Trans>Wrong email or password</Trans>
                  </Alert>
                )) ||
                  (httpResponse.error_code === "40102" && (
                    <Alert variant="outlined" severity="info">
                      <Trans>no pw set</Trans>
                    </Alert>
                  )) ||
                  (httpResponse.error_code === "500" && (
                    <Alert variant="outlined" severity="error">
                      <Trans>
                        Something went wrong. This problem is with the server.
                        Please contact support if the problem persists.
                      </Trans>
                    </Alert>
                  )) ||
                  (httpResponse.status === "success" && (
                    <Alert variant="outlined" severity="success">
                      <Trans>Login successful.</Trans>
                    </Alert>
                  )) ||
                  (httpResponse && (
                    //Default
                    <Alert variant="outlined" severity="error">
                      <Trans>
                        Something went wrong. Please contact admin if the
                        problem persists.
                      </Trans>
                    </Alert>
                  ))}
                <Field
                  component={TextField}
                  name="email"
                  type="email"
                  label={t`Email`}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  autoComplete="email"
                  autoFocus
                />
                <br />
                <Field
                  component={TextField}
                  type="password"
                  label={t`Password`}
                  name="password"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  autoComplete="current-password"
                />
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name="remember"
                  color="primary"
                  Label={{ label: t`remember me` }}
                  id="remember"
                />
                {isSubmitting && <LinearProgress />}
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  type="submit"
                  fullWidth
                  className={classes.submit}
                >
                  <Trans>Login</Trans>
                </Button>
              </Form>
            )}
          </Formik>
          <Grid container>
            <Grid item xs>
              <Link component={ReactLink} to="reset" variant="body2">
                <Trans>forgot password</Trans>
              </Link>
            </Grid>
            <Grid item>
              <Link component={ReactLink} to="register" variant="body2">
                <Trans>noaccount signup</Trans>
              </Link>
            </Grid>
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
          {/* </form> */}
        </div>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        lg={8}
        xl={9}
        className={classes.image}
      />
    </Grid>
  );
}
